<template>
  <v-navigation-drawer
    app
    :permanent=true
    width="400px"
    color="#F8F8F8"
  >
    <NavigationList></NavigationList>
  </v-navigation-drawer>
</template>

<script>
import NavigationList from "@/components/list/NavigationList";
import userService from "@/http/service/user-service";

export default {
  name: "Navigation",
  components: {
    NavigationList,
  },
  async mounted() {
    userService.getDetails().then(({data}) => {
      this.user.firstName = data.first_name;
      this.user.lastName = data.last_name
    })
  },
  data() {
    return {
      active: true,
      user: {
        firstName: '',
        lastName: ''
      }
    }
  },
  methods: {
    logout() {
      userService.logout().then(() => {
        window.localStorage.setItem("auth", JSON.stringify(false))
      }).finally(() => {
        this.$router.push({name: 'Login'})
      })
    }
  }
}
</script>

<style lang="scss">

.v-navigation-drawer {
  z-index: 0 !important;
}

.logout-icon {
  &:hover {
    cursor: pointer;
  }
}

</style>