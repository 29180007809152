<template>
  <v-app>
    <Header></Header>
    <Navigation></Navigation>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/header/Header.vue"
import Navigation from "@/components/Navigation";

export default {
  name: 'Default',
  components: {
    Navigation,
    Header
  }
}
</script>

<style lang="scss">
.header {
  background-color: red;
}
</style>
